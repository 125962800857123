'use client';

import { ErrorPageContainer } from '../components/ErrorPageContainer';

/**
 * This is the Root error handler. It should capture any errors in the children
 * that inherit from the root layout. We should always be providing enough inside the root
 * layout for this handler to render correctly, so it doesn't need any extra providers here.
 */
export default function RootError({
    error,
    reset,
}: {
    error: unknown;
    reset: () => void;
}): JSX.Element {
    return <ErrorPageContainer error={error} reset={reset} />;
}
RootError.displayName = 'RootError';
